import React from 'react';

import FieldWork from './views/FieldWork/FieldWork';
import FieldConstruction from './views/FieldWork/Construction';
import FieldCapture from './views/FieldWork/Capture';
import FieldCapture2 from './views/FieldWork/Capture2';
import FieldConstructionPhotos from './views/FieldWork/Photos';
import FieldAttachments from './views/FieldWork/Attachments';

const Breadcrumbs = React.lazy(() => import('./views/Base/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/Base/Cards'));
const Carousels = React.lazy(() => import('./views/Base/Carousels'));
const Collapses = React.lazy(() => import('./views/Base/Collapses'));
const Dropdowns = React.lazy(() => import('./views/Base/Dropdowns'));
const Forms = React.lazy(() => import('./views/Base/Forms'));
const Jumbotrons = React.lazy(() => import('./views/Base/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/Base/ListGroups'));
const Navbars = React.lazy(() => import('./views/Base/Navbars'));
const Navs = React.lazy(() => import('./views/Base/Navs'));
const Paginations = React.lazy(() => import('./views/Base/Paginations'));
const Popovers = React.lazy(() => import('./views/Base/Popovers'));
const ProgressBar = React.lazy(() => import('./views/Base/ProgressBar'));
const Switches = React.lazy(() => import('./views/Base/Switches'));
const Tables = React.lazy(() => import('./views/Base/Tables'));
const Tabs = React.lazy(() => import('./views/Base/Tabs'));
const Tooltips = React.lazy(() => import('./views/Base/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/Buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/Buttons/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/Buttons/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/Buttons/Buttons'));
const Charts = React.lazy(() => import('./views/Charts'));
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const CoreUIIcons = React.lazy(() => import('./views/Icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/Icons/Flags'));
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome'));
const SimpleLineIcons = React.lazy(() => import('./views/Icons/SimpleLineIcons'));
const Alerts = React.lazy(() => import('./views/Notifications/Alerts'));
const Badges = React.lazy(() => import('./views/Notifications/Badges'));
const Modals = React.lazy(() => import('./views/Notifications/Modals'));
const Colors = React.lazy(() => import('./views/Theme/Colors'));
const Typography = React.lazy(() => import('./views/Theme/Typography'));
const Widgets = React.lazy(() => import('./views/Widgets/Widgets'));
const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));
const EditUser = React.lazy(() => import('./views/Users/EditUser'));
const Orders = React.lazy(() => import('./views/Orders/Orders'));
const Order = React.lazy(() => import('./views/Orders/Order'));
const EditOrder = React.lazy(() => import('./views/Orders/EditOrder'));
const Logs = React.lazy(() => import('./views/Logging/Logs'));
const Projects = React.lazy(() => import('./views/Projects/Projects'));
const Project = React.lazy(() => import('./views/Projects/Project'));
const Tasks = React.lazy(() => import('./views/Tasks/Tasks'));
const Task = React.lazy(() => import('./views/Tasks/Task'));
const Emails = React.lazy(() => import('./views/Emails/Emails'));
const Email = React.lazy(() => import('./views/Emails/Email'));
const EmailTagAttachments = React.lazy(() => import('./views/Emails/EmailTagAttachments'));
const PitAndPipeList = React.lazy(() => import('./views/PitAndPipe/PitAndPipeList'));
const XeroIntegration = React.lazy(() => import('./views/Finance/XeroIntegration'));
const Finance = React.lazy(() => import('./views/Finance/Finance'));
const ItemCodes = React.lazy(() => import('./views/ItemCodes/ItemCodes'));
const Lookups = React.lazy(() => import('./views/Lookups/Lookups'));
const Groups = React.lazy(() => import('./views/Groups/Groups'));
const Group = React.lazy(() => import('./views/Groups/Group'));
const EditGroup = React.lazy(() => import('./views/Groups/EditGroup'));
const IndependentContractors = React.lazy(() => import('./views/IndependentContractors/IndependentContractors'));
const Constructions = React.lazy(() => import('./views/Construction/Search'));
const ConstructionItemCodes = React.lazy(() => import('./views/Construction/ItemCodes'));
const SubcontractorCodes = React.lazy(() => import('./views/Construction/ItemChildCodes'));
const ConstructionEdit = React.lazy(() => import('./views/Construction/Edit'));
const ConstructionView = React.lazy(() => import('./views/Construction/View'));
const ConstructionBulkImport = React.lazy(() => import('./views/Construction/BulkImport'));
const Reports = React.lazy(() => import('./views/Reports/Reports'));
const ConstructionDailyRun = React.lazy(() => import('./views/Reports/ConstructionDailyRun'));
const ConstructionClaims = React.lazy(() => import('./views/Reports/ConstructionClaims'));
const ConstructionInvoicing = React.lazy(() => import('./views/Reports/ConstructionInvoicing'));
const ConstructionPayment = React.lazy(() => import('./views/Reports/ConstructionPayment'));
const SchedulingTool = React.lazy(() => import('./views/SchedulingTool/SchedulingTool'));
const SchedulingToolOld = React.lazy(() => import('./views/SchedulingTool/SchedulingToolOld'));
const ScheduleOverview = React.lazy(() => import('./views/SchedulingTool/ScheduleOverview'));
const TicketOfWork = React.lazy(() => import('./views/FieldWork/TicketOfWork'));
const SystemSettings = React.lazy(() => import('./views/SystemSettings/View'));
const DashboardSchedule = React.lazy(() => import('./views/Dashboard/Schedule'));
const BuilderApprovals = React.lazy(() => import('./views/BuilderApprovals/BuilderApprovals'));
const PotentialJobsView = React.lazy(() => import('./views/PotentialJobs/PotentialJobsView'));
const AdminTasks = React.lazy(() => import('./views/AdminTasks/AdminTasks'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/dashboard/schedule', exact: true, name: 'Schedule', component: DashboardSchedule, layout: 'NoChrome' },
  {
    path: '/dashboard/schedule/:groupid',
    exact: true,
    name: 'Schedule',
    component: DashboardSchedule,
    layout: 'NoChrome'
  },
  { path: '/field/:tab', exact: true, name: 'Field Work', component: FieldWork, role: 'Fieldwork' },
  { path: '/field/cards', name: 'Field Work', component: FieldWork, role: 'Fieldwork' },

  {
    path: '/field/construction/:constructionId/:taskId',
    exact: true,
    name: 'Construction',
    component: FieldConstruction,
    role: 'Fieldwork'
  },
  {
    path: '/field/construction/:id/capture/:taskId',
    exact: true,
    name: 'Capture',
    component: FieldCapture,
    role: 'Fieldwork',
    layout: 'NoChrome'
  },
  {
    path: '/field/construction/:id/capture2/:taskId',
    exact: true,
    name: 'Capture2',
    component: FieldCapture2,
    role: 'Fieldwork',
    layout: 'NoChrome'
  },
  {
    path: '/field/construction/:id/:taskId/attachments',
    exact: true,
    name: 'Attachments',
    component: FieldAttachments,
    role: 'Fieldwork'
  },
  {
    path: '/field/construction/:id/photos',
    exact: true,
    name: 'Photos',
    component: FieldConstructionPhotos,
    role: 'Fieldwork',
    layout: 'NoChrome'
  },
  {
    path: '/field/task/:constructionId/:taskId/ticket-of-work',
    exact: true,
    name: 'Ticket of Work',
    component: TicketOfWork,
    role: 'Program: Construction',
    layout: 'NoChrome'
  },
  { path: '/theme', exact: true, name: 'Theme', component: Colors, role: 'SuperUser' },
  { path: '/theme/colors', name: 'Colors', component: Colors, role: 'SuperUser' },
  { path: '/theme/typography', name: 'Typography', component: Typography, role: 'SuperUser' },
  { path: '/base', exact: true, name: 'Base', component: Cards, role: 'SuperUser' },
  { path: '/base/cards', name: 'Cards', component: Cards, role: 'SuperUser' },
  { path: '/base/forms', name: 'Forms', component: Forms, role: 'SuperUser' },
  { path: '/base/switches', name: 'Switches', component: Switches, role: 'SuperUser' },
  { path: '/base/tables', name: 'Tables', component: Tables, role: 'SuperUser' },
  { path: '/base/tabs', name: 'Tabs', component: Tabs, role: 'SuperUser' },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs, role: 'SuperUser' },
  { path: '/base/carousels', name: 'Carousel', component: Carousels, role: 'SuperUser' },
  { path: '/base/collapses', name: 'Collapse', component: Collapses, role: 'SuperUser' },
  { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns, role: 'SuperUser' },
  { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons, role: 'SuperUser' },
  { path: '/base/list-groups', name: 'List Groups', component: ListGroups, role: 'SuperUser' },
  { path: '/base/navbars', name: 'Navbars', component: Navbars, role: 'SuperUser' },
  { path: '/base/navs', name: 'Navs', component: Navs, role: 'SuperUser' },
  { path: '/base/paginations', name: 'Paginations', component: Paginations, role: 'SuperUser' },
  { path: '/base/popovers', name: 'Popovers', component: Popovers, role: 'SuperUser' },
  { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar, role: 'SuperUser' },
  { path: '/base/tooltips', name: 'Tooltips', component: Tooltips, role: 'SuperUser' },
  { path: '/buttons', exact: true, name: 'Buttons', component: Buttons, role: 'SuperUser' },
  { path: '/buttons/buttons', name: 'Buttons', component: Buttons, role: 'SuperUser' },
  { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns, role: 'SuperUser' },
  { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups, role: 'SuperUser' },
  { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons, role: 'SuperUser' },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons, role: 'SuperUser' },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons, role: 'SuperUser' },
  { path: '/icons/flags', name: 'Flags', component: Flags, role: 'SuperUser' },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome, role: 'SuperUser' },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons, role: 'SuperUser' },
  { path: '/notifications', exact: true, name: 'Notifications', component: Alerts, role: 'SuperUser' },
  { path: '/notifications/alerts', name: 'Alerts', component: Alerts, role: 'SuperUser' },
  { path: '/notifications/badges', name: 'Badges', component: Badges, role: 'SuperUser' },
  { path: '/notifications/modals', name: 'Modals', component: Modals, role: 'SuperUser' },
  { path: '/widgets', name: 'Widgets', component: Widgets, role: 'SuperUser' },
  { path: '/charts', name: 'Charts', component: Charts, role: 'SuperUser' },
  { path: '/admin/users', exact: true, name: 'Users', component: Users, role: 'Administrator' },
  { path: '/admin/users/:id', exact: true, name: 'User Details', component: User, role: 'Administrator' },
  { path: '/admin/users/:id/edit', name: 'Edit User', component: EditUser, role: 'Administrator' },
  { path: '/orders', exact: true, name: 'Orders', component: Orders, role: 'Administrator' },
  { path: '/orders/:id', exact: true, name: 'Order Details', component: Order },
  { path: '/orders/:id/edit', exact: true, name: 'Edit Order', component: EditOrder, role: 'Administrator' },
  // { path: '/preinstalls', exact: true, name: 'Preinstalls', component: Preinstalls, role: 'Program: Preinstall' },
  // { path: '/preinstalls/:id', exact: true, name: 'Preinstall Details', component: Preinstall },
  // { path: '/preinstalls/:id/:tab', exact: true, component: Preinstall },
  // { path: '/preinstalls/:projectId/tasks/:id', exact: true, name: 'Task Details', component: Task },
  // { path: '/preinstall-request/:id/edit', exact: true, name: 'Edit Preinstall Request', component: EditPreinstall },
  { path: '/logs', exact: true, name: 'Logs', component: Logs, role: 'Administrator' },
  { path: '/projects', exact: true, name: 'Projects', component: Projects },
  { path: '/project/:id', name: 'Project Details', component: Project },
  { path: '/tasks', exact: true, name: 'Tasks', component: Tasks },
  { path: '/tasks/:id', name: 'Task', component: Task },
  { path: '/emails', exact: true, name: 'Emails', component: Emails, role: 'Administrator' },
  { path: '/emails/:id', exact: true, name: 'Email Details', component: Email, role: 'Administrator' },
  { path: '/emails/:id/attachments', exact: true, name: 'Tag Attachments', component: EmailTagAttachments },
  { path: '/pit-pipe', exact: true, name: 'Pit & Pipe', component: PitAndPipeList, role: 'Program: Pit & Pipe' },
  { path: '/finance', exact: true, name: 'Finance', component: Finance, role: 'Administrator' },
  { path: '/finance/xero', exact: true, name: 'Xero Integration', component: XeroIntegration, role: 'Administrator' },
  { path: '/admin', exact: true, name: 'Admin', role: 'Administrator' },
  {
    path: '/admin/itemcodes',
    exact: true,
    name: 'Pre-install Item Codes',
    component: ItemCodes,
    role: 'Administrator'
  },
  { path: '/admin/groups', exact: true, name: 'Groups', component: Groups, role: 'Administrator' },
  { path: '/admin/groups/:id', exact: true, name: 'Group Details', component: Group, role: 'Administrator' },
  { path: '/admin/groups/:id/edit', exact: true, name: 'Edit Group', component: EditGroup, role: 'Administrator' },
  {
    path: '/admin/independent-contractors',
    exact: true,
    name: 'Independent Contractors',
    component: IndependentContractors,
    role: 'Administrator'
  },
  { path: '/admin/lookups', exact: true, name: 'Lookups', component: Lookups, role: 'Administrator' },
  { path: '/admin/settings', exact: true, name: 'System Settings', component: SystemSettings, role: 'Administrator' },
  {
    path: '/admin/settings/:tab',
    exact: true,
    name: 'System Settings',
    component: SystemSettings,
    role: 'Administrator'
  },
  {
    path: '/admin/settings/jobSettings/:tab/:worktype',
    exact: true,
    name: 'jobSettings',
    component: SystemSettings,
    role: 'Administrator'
  },
  // temp, this is a way to reference the old data
  {
    path: '/admin/settings/jobSettings/:tab',
    exact: true,
    name: 'jobSettings',
    component: SystemSettings,
    role: 'Administrator'
  },
  {
    path: '/jobs',
    exact: true,
    name: 'Construction Search',
    component: Constructions,
    role: 'Program: Construction'
  },
  {
    path: '/potentialjobs',
    exact: true,
    name: 'Potential Jobs',
    component: PotentialJobsView,
    role: 'Program: Construction'
  },
  {
    path: '/potentialjobs/:tab',
    exact: true,
    name: 'Potential Jobs',
    component: PotentialJobsView,
    role: 'Program: Construction'
  },
  {
    path: '/scheduling-tool',
    exact: true,
    name: 'Scheduling Tool',
    component: SchedulingTool,
    role: 'Scheduling'
  },
  {
    path: '/scheduling-tool/:date',
    exact: true,
    name: 'Scheduling Tool',
    component: SchedulingTool,
    role: 'Scheduling'
  },
  {
    path: '/scheduling-tool-old',
    exact: true,
    name: 'Scheduling Tool Old',
    component: SchedulingToolOld,
    role: 'Program: Construction'
  },
  {
    path: '/scheduling-tool-old/:date',
    exact: true,
    name: 'Scheduling Tool Old',
    component: SchedulingToolOld,
    role: 'Program: Construction'
  },
  {
    path: '/schedule-overview',
    exact: true,
    name: 'Schedule Overview',
    component: ScheduleOverview,
    role: 'Scheduling'
  },
  {
    path: '/admin/constructioncodes',
    exact: true,
    name: 'Construction Item Codes',
    component: ConstructionItemCodes,
    role: 'Administrator'
  },
  {
    path: '/admin/constructioncodes/:id',
    exact: true,
    name: 'Construction Item Codes',
    component: ConstructionItemCodes,
    role: 'Administrator'
  },
  {
    path: '/admin/childcodes',
    exact: true,
    name: 'Subcontractor Codes',
    component: SubcontractorCodes,
    role: 'Administrator'
  },
  {
    path: '/admin/childcodes/:id',
    exact: true,
    name: 'Subcontractor Codes',
    component: SubcontractorCodes,
    role: 'Administrator'
  },
  {
    path: '/builderapprovals',
    exact: true,
    name: 'Builder Approvals',
    component: BuilderApprovals,
    role: 'Fieldwork'
  },
  {
    path: '/builderapprovals/:tab',
    exact: true,
    name: 'Builder Approvals',
    component: BuilderApprovals,
    role: 'Fieldwork'
  },
  {
    path: '/admin/admintasks',
    exact: true,
    name: 'Admin Tasks',
    component: AdminTasks,
    role: 'Administrator'
  },
  {
    path: '/construction/:id/edit',
    exact: true,
    name: 'Construction Edit',
    component: ConstructionEdit,
    role: 'Program: Construction'
  },
  // { path: '/construction/:id/ticket-of-work', exact: true, name: 'Ticket of Work', component: TicketOfWork, role: 'Program: Construction', layout: 'NoChrome' },
  {
    path: '/construction/:id',
    exact: true,
    name: 'Construction View',
    component: ConstructionView,
    role: 'Program: Construction'
  },
  {
    path: '/construction/:id/:tab',
    exact: true,
    name: 'Construction View',
    component: ConstructionView,
    role: 'Program: Construction'
  },
  {
    path: '/construction-bulk-import',
    exact: true,
    name: 'Construction Bulk Import',
    component: ConstructionBulkImport,
    role: 'Program: Construction'
  },
  { path: '/reports', exact: true, name: 'Reports', component: Reports, role: 'Administrator' },
  {
    path: '/reports/construction-daily-run',
    exact: true,
    name: 'Construction: Daily Run',
    component: ConstructionDailyRun,
    role: 'Program: Construction'
  },
  {
    path: '/reports/construction-claims',
    exact: true,
    name: 'Construction: Claims',
    component: ConstructionClaims,
    role: 'Program: Construction'
  },
  {
    path: '/reports/construction-invoicing',
    exact: true,
    name: 'Construction: Invoicing',
    component: ConstructionInvoicing,
    role: 'Program: Construction'
  },
  {
    path: '/reports/construction-payment',
    exact: true,
    name: 'Construction: Payment',
    component: ConstructionPayment,
    role: 'Program: Construction'
  }
];

export default routes;
