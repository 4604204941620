import React, { useState, useMemo } from 'react';
import { Container, Col, Row, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getAddress } from '../../utils/utilFunctions';
import { useCompletedWork } from './DataHooks';

const TaskHistory = ({ structuredAddressWorkTypes, groupId }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [pastMonths, setPastMonths] = useState('1');
  const [searchFilter, setSearchFilter] = useState(null);

  const { completedWork, loading } = useCompletedWork(groupId, pastMonths);

  const getDisplayStatus = (task) => {
    if (task.paid) return 'Paid';
    if (task.approved) return 'Approve for Payment';
    return 'Completed';
  };

  const toggleRowExpand = (id) => {
    const index = expandedRows.indexOf(id);
    if (index !== -1) {
      expandedRows.splice(index, 1);
    } else {
      expandedRows.push(id);
    }

    setExpandedRows([...expandedRows]);
  };

  const getTotal = (item) => {
    return item.completedQty
      ? item.completedQty * (item.rate ?? 0)
      : item.quantity
      ? item.quantity * (item.rate ?? 0)
      : 0;
  };

  const filteredTasks = useMemo(() => {
    if (!completedWork || !completedWork.tasks) return;

    let tasks = completedWork.tasks;

    const lowerCaseSearchFilter = searchFilter?.toLowerCase();
    if (lowerCaseSearchFilter) {
      tasks = tasks.filter((task) => {
        const taskName = task.name.toLowerCase();
        const taskAddress = structuredAddressWorkTypes?.includes(task.workType)
          ? getAddress(task)?.toLowerCase()
          : task.address?.toLowerCase();
        const taskWorkOrder = task.workOrder?.toLowerCase();
        const displayStatus = getDisplayStatus(task).toLowerCase();

        return (
          taskName?.includes(lowerCaseSearchFilter) ||
          taskAddress?.includes(lowerCaseSearchFilter) ||
          taskWorkOrder?.includes(lowerCaseSearchFilter) ||
          displayStatus?.includes(lowerCaseSearchFilter)
        );
      });
    }

    return tasks;
  }, [completedWork, searchFilter]);

  const getComments = (task, item) => {
    const payment = task.payments.find((x) => x.name === task.name && x.owner === task.resource);
    return payment?.paymentItems?.find((x) => x.itemCode === item.childCode)?.comments;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Row className="pt-1">
        <Col md="auto" className="pr-0">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            style={{ width: '250px' }} // Set the width of the search input
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </Col>
        <Col md="auto" className="pr-0">
          <select
            style={{ width: '120px' }}
            className="form-control"
            value={pastMonths}
            onChange={(e) => setPastMonths(e.target.value)}
          >
            <option value={1}>1 month</option>
            <option value={3}>3 months</option>
            <option value={6}>6 months</option>
            <option value={12}>1 year</option>
          </select>
        </Col>
        <Col md="auto" className="pt-2">
          Completed tasks for the past {pastMonths === '1' ? 'month' : pastMonths + ' months'}
        </Col>
      </Row>
      {filteredTasks && structuredAddressWorkTypes && (
        <Container fluid className="p-2">
          {filteredTasks.map((task) => (
            <Row className="pt-1" key={task.id}>
              <Col>
                <Row>
                  <Col>
                    <h5>
                      <Button className="px-2 py-1" tabIndex="-1" onClick={() => toggleRowExpand(task.id)}>
                        <i className={`fa ${expandedRows.includes(task.id) ? 'fa-minus' : 'fa-plus'}`}></i>
                      </Button>{' '}
                      {task.dp} -{' '}
                      <Link to={`/field/construction/${task.constructionId}/${task.id}`}>{task.workOrder}</Link> (
                      {getDisplayStatus(task)}) -{' '}
                      {structuredAddressWorkTypes?.includes(task.workType) ? getAddress(task) : task.address} -{' '}
                      {task.name} - <span className="text-danger">{task.completedDate.substr(0, 10)}</span>
                    </h5>
                  </Col>
                </Row>
                {expandedRows.includes(task.id) ? (
                  <Table responsive hover size="sm">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Qty/UOM</th>
                        <th>Description</th>
                        <th style={{ width: '90px', textAlign: 'right', paddingRight: '20px' }}>Rate</th>
                        <th style={{ width: '110px', textAlign: 'right', paddingRight: '20px' }}>Total</th>
                        <th>Variation Reason</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {task.items
                        .filter((x) => !!x.childCode)
                        .map((item) => {
                          const boq = task.boqs.find((b) => b.id === item.boqId);
                          return (
                            <tr key={item.id}>
                              <td>{item.childCode}</td>
                              <td>{boq?.itemType}</td>
                              <td>
                                {item.completedQty ?? item.quantity ?? 0} {boq.unitOfMeasure}
                              </td>
                              <td>{boq.description}</td>
                              <td style={{ textAlign: 'right', paddingRight: '20px' }}>
                                {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(item.rate)}
                              </td>
                              <td style={{ textAlign: 'right', paddingRight: '20px' }}>
                                {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(
                                  getTotal(item)
                                )}
                              </td>
                              <td>{item.variationReason}</td>
                              <td>{getComments(task, item)}</td>
                            </tr>
                          );
                        })}
                      <tr>
                        <td colSpan={5}></td>
                        <td style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '20px' }}>
                          {Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(
                            task.items.filter((x) => !!x.childCode).reduce((p, c) => p + getTotal(c), 0)
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : null}
              </Col>
            </Row>
          ))}
        </Container>
      )}
    </>
  );
};

export default TaskHistory;
