import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Table } from 'reactstrap';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DraftBuilderApprovalMessage from '../BuilderApprovals/DraftBuilderApprovalMessage';
import { getBaseUrl, getAddress, getAddressRange } from '../../utils/utilFunctions';
import { convertPhoneNumber, getIsValidPhoneNumber } from '../BuilderApprovals/BuilderUtils';

const GET_REFERENCE_DATA = gql`
  query GetReferenceData {
    builderLookup
    jobStatuses: systemSettings(where: { path: "Name", comparison: equal, value: "Job Status Settings" }) {
      id
      name
      workType
      config
    }
    addressTypeSettings: systemSettings(where: { path: "Name", comparison: equal, value: "Address Type" }) {
      id
      name
      workType
      config
    }
  }
`;

const UPDATE_POTENTIAL_JOB = gql`
  mutation UpdatePotentialJob($potentialJob: PotentialJobInput!, $processedFromOffice: Boolean) {
    updatePotentialJob(potentialJob: $potentialJob, processedFromOffice: $processedFromOffice) {
      id
      builder
      siteContactName
      siteContactPhone
      status
      lotNumber
      unitNumber
      streetNumber
      streetName
      roadType
      suburb
      state
      postCode
      assignedFieldUserId
      addressFromField
      needsOfficeApproval
    }
  }
`;

const SEND_POTENTIAL_JOB_REQUEST = gql`
  mutation SendRequest($ids: [Int]!, $message: String!) {
    sendPotentialJobRequest(ids: $ids, message: $message)
  }
`;

const FormField = ({ label, children }) => {
  return (
    <Row className="pb-1">
      <Col md="12" lg="3" className="pt-1">
        <label className="mb-0">{label}</label>
      </Col>
      <Col md="12" lg="9">
        {children}
      </Col>
    </Row>
  );
};

const NewPotentialJob = ({
  showPotentialJobModal,
  setShowPotentialJobModal,
  jobData,
  onJobUpdated,
  fullAddressOnly
}) => {
  const [builderList, setBuilderList] = useState(null);
  //const [statusList, setStatusList] = useState([]);
  const [selectedBuilder, setSelectedBuilder] = useState(!!jobData ? jobData.builder : '');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [showBuilderApproval, setShowBuilderApproval] = useState(false);
  const [selectedJobsData, setSelectedJobsData] = useState(null);
  const [showFullAddress, setShowFullAddress] = useState(!!jobData || fullAddressOnly);

  const { assignedFieldUser, potentialJobComments, ...filteredJobData } = jobData
    ? { ...jobData, needsOfficeApproval: false }
    : {};

  const [potentialJob, setPotentialJob] = useState(
    jobData
      ? filteredJobData
      : {
          builder: '',
          siteContactName: '',
          siteContactPhone: '',
          status: '',
          addressFromField: '',
          lotNumber: '',
          lotRangeLow: null,
          lotRangeHigh: null,
          unitNumber: '',
          unitRangeLow: null,
          unitRangeHigh: null,
          streetNumber: '',
          streetRangeLow: null,
          streetRangeHigh: null,
          streetName: '',
          roadType: '',
          suburb: '',
          state: '',
          postCode: '',
          comments: '',
          needsOfficeApproval: fullAddressOnly ? false : true
        }
  );

  const { data } = useQuery(GET_REFERENCE_DATA);

  const [updatePotentialJob] = useMutation(UPDATE_POTENTIAL_JOB, {
    onCompleted: (data) => {
      if (data.updatePotentialJob && data.updatePotentialJob[0]) {
        toast.success('Potential Job added successfully');
        setIsValidPhoneNumber(getIsValidPhoneNumber(convertPhoneNumber(data.updatePotentialJob[0].siteContactPhone)));
        const _selectedJobsData = getSelectedJobsData(data.updatePotentialJob);
        setSelectedJobsData(_selectedJobsData);
        if (onJobUpdated) {
          onJobUpdated(data.updatePotentialJob);
        }
      }
    },
    onError: (error) => {
      toast.error('Error updating job: ' + error.message);
    }
  });

  const [sendRequestToBuilder] = useMutation(SEND_POTENTIAL_JOB_REQUEST, {
    onCompleted: (data) => {
      if (data.sendPotentialJobRequest) {
        toast.success('Request sent successfully');
        setShowPotentialJobModal(false);
      }
    },
    onError: (error) => {
      toast.error('Error sending request: ' + error.message);
    }
  });

  useEffect(() => {
    if (!data) return;
    setBuilderList(data.builderLookup);

    if (data.addressTypeSettings) {
      const addressTypeSettings = data.addressTypeSettings.map((setting) => ({
        workType: setting.workType,
        values: JSON.parse(setting.config).addressSetting
      }));

      let bookableWorkTypes = [];

      addressTypeSettings.forEach((setting) => {
        if (setting.values?.bookable) {
          bookableWorkTypes.push(setting.workType);
        }
      });

      // if (data.jobStatuses) {
      //   const jobStatuses = data.jobStatuses.map((setting) => ({
      //     workType: setting.workType,
      //     statuses: JSON.parse(setting.config).statuses
      //   }));

      //   let statuses = [];

      //   jobStatuses.forEach((setting) => {
      //     if (bookableWorkTypes.includes(setting.workType)) {
      //       if (setting.statuses) {
      //         setting.statuses.forEach((status) => {
      //           if (status.builderApproval) {
      //             statuses.push(status.statusName);
      //           }
      //         });
      //       }
      //     }
      //   });

      //   statuses = [...new Set(statuses)];

      //   setStatusList(
      //     statuses.map((status) => ({
      //       value: status,
      //       label: status
      //     }))
      //   );
      // }
    }
  }, [data]);

  useEffect(() => {
    setPotentialJob((prev) => ({
      ...prev,
      builder: selectedBuilder
    }));
  }, [selectedBuilder]);

  const handleCloseApproval = () => {
    setShowBuilderApproval(false);
    setSelectedJobsData(null);
    setShowPotentialJobModal(false);
  };

  const getSelectedJobsData = (createdPotentialJobs) => {
    const fullAddress = getAddressRange(createdPotentialJobs);
    const potentialJobIds = createdPotentialJobs.map((job) => job.id);
    createdPotentialJobs.forEach((job) => {
      job.address = job.needsOfficeApproval ? job.addressFromField : getAddress(job);
      job.selected = true;
    });

    const slug = btoa(
      `${createdPotentialJobs[0].siteContactName}${
        createdPotentialJobs[0].siteContactPhone.startsWith('+') ? '' : '+'
      }${createdPotentialJobs[0].siteContactPhone}+${''}+${potentialJobIds.join(',')}`
    );
    const baseUrl = getBaseUrl();
    const link = baseUrl + '/builders/' + slug;
    // ToDo (one day): create a System Setting for domain display name
    let domainName = 'Adtech Contracting Pty Ltd';
    if (baseUrl.includes('onewire')) {
      domainName = 'One Wire Pty Ltd';
    }

    return {
      siteContact: createdPotentialJobs[0].siteContactName,
      phoneNumber: createdPotentialJobs[0].siteContactPhone,
      jobs: createdPotentialJobs,
      addressText: fullAddress,
      message: `Dear Builder,\n\nRE: ${fullAddress}.\n\nWe are contacting you as the preferred NBN preinstall contractor in the area for your current / upcoming builds.\n\nWhilst we roll out NBN's FTTP network, in order to ensure a smooth, cost free and efficient installation, we kindly request that you ensure site access is available to the property and the garage.\n\nPlease click on the link below to approve site access to the property and to avoid connection delay.\n\nIf you do not wish for the NBN preinstall to be completed, please click on the below link and select the "Deny" option.\n\n${link}\n\nYours sincerely, ${domainName}`
    };
  };

  const requestSent = () => {
    const potentialJobIds = selectedJobsData.jobs.map((job) => job.id);
    const message = selectedJobsData.message.replace(/\n/g, ' ');
    sendRequestToBuilder({ variables: { ids: potentialJobIds, message: message } });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('Range')) {
      setPotentialJob({ ...potentialJob, [name]: value ? Number(value) : null });
    } else {
      setPotentialJob({ ...potentialJob, [name]: value });
    }
  };

  // const testJobs = [
  //   {
  //     lotNumber: 12,
  //     unitNumber: 101,
  //     streetNumber: 100,
  //     streetName: 'Main St',
  //     roadType: 'Rd',
  //     suburb: 'Suburbia',
  //     state: 'State',
  //     postCode: '12345'
  //   },
  //   {
  //     lotNumber: 12,
  //     unitNumber: 102,
  //     streetNumber: 100,
  //     streetName: 'Main St',
  //     roadType: 'Rd',
  //     suburb: 'Suburbia',
  //     state: 'State',
  //     postCode: '12345'
  //   }
  // ];

  // const testJobs = [
  //   { lotNumber: 12, streetName: 'Main St', roadType: 'Rd', suburb: 'Suburbia', state: 'State', postCode: '12345' },
  //   { lotNumber: 13, streetName: 'Main St', roadType: 'Rd', suburb: 'Suburbia', state: 'State', postCode: '12345' }
  // ];

  //console.log('getAddressRange', getAddressRange(testJobs));

  return (
    <>
      <Modal isOpen={showPotentialJobModal} toggle={() => setShowPotentialJobModal(!showPotentialJobModal)} size="lg">
        <ModalHeader>{!!jobData ? 'Process Potential Job' : 'New Potential Job'}</ModalHeader>
        {!selectedJobsData && (
          <ModalBody style={{ backgroundColor: '#EBECED' }}>
            <form>
              {!!jobData && (
                <div className="pb-1 font-weight-bolder">
                  <FormField label="Address From Field">
                    <div>{potentialJob.addressFromField}</div>
                  </FormField>
                </div>
              )}
              <FormField label="Builder">
                {builderList !== null && (
                  <BuilderSelect
                    builderList={builderList}
                    selectedBuilder={selectedBuilder}
                    setSelectedBuilder={setSelectedBuilder}
                  />
                )}
              </FormField>
              <FormField label="Site Contact Name">
                <input
                  type="text"
                  name="siteContactName"
                  value={potentialJob.siteContactName}
                  onChange={handleChange}
                  className="form-control"
                />
              </FormField>
              <FormField label="Site Contact Phone">
                <input
                  type="text"
                  name="siteContactPhone"
                  value={potentialJob.siteContactPhone}
                  onChange={handleChange}
                  className="form-control"
                />
              </FormField>
              {/* <FormField label="Status">
                <Select
                  options={statusList}
                  value={statusList.find((status) => status.value === potentialJob.status)}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setPotentialJob({ ...potentialJob, status: selectedOption.value });
                    } else {
                      setPotentialJob({ ...potentialJob, status: '' });
                    }
                  }}
                  isClearable
                  placeholder="Select a status"
                  classNamePrefix="react-select"
                />
              </FormField> */}
              {!showFullAddress && (
                <FormField label="Address">
                  <input
                    type="text"
                    name="addressFromField"
                    value={potentialJob.addressFromField}
                    onChange={handleChange}
                    className="form-control"
                  />
                </FormField>
              )}
              {showFullAddress && (
                <>
                  <div className="pt-3">
                    <FormField label="Lot Number">
                      <input
                        type="text"
                        name="lotNumber"
                        value={potentialJob.lotNumber}
                        onChange={handleChange}
                        className="form-control"
                        disabled={!!potentialJob.lotRangeLow || !!potentialJob.lotRangeHigh}
                      />
                    </FormField>
                  </div>

                  <FormField label="Lot Range">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="number"
                        name="lotRangeLow"
                        defaultValue={potentialJob.lotRangeLow}
                        onChange={handleChange}
                        className="form-control mr-2"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!potentialJob.lotNumber ||
                          !!potentialJob.unitRangeLow ||
                          !!potentialJob.unitRangeHigh ||
                          !!potentialJob.streetRangeLow ||
                          !!potentialJob.streetRangeHigh
                        }
                      />
                      <span className="mr-2">-</span>
                      <input
                        type="number"
                        name="lotRangeHigh"
                        defaultValue={potentialJob.lotRangeHigh}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!potentialJob.lotNumber ||
                          !!potentialJob.unitRangeLow ||
                          !!potentialJob.unitRangeHigh ||
                          !!potentialJob.streetRangeLow ||
                          !!potentialJob.streetRangeHigh
                        }
                      />
                    </div>
                  </FormField>
                  <FormField label="Unit Number">
                    <input
                      type="text"
                      name="unitNumber"
                      value={potentialJob.unitNumber}
                      onChange={handleChange}
                      className="form-control"
                      disabled={!!potentialJob.unitRangeLow || !!potentialJob.unitRangeHigh}
                    />
                  </FormField>
                  <FormField label="Unit Range">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="number"
                        name="unitRangeLow"
                        defaultValue={potentialJob.unitRangeLow}
                        onChange={handleChange}
                        className="form-control mr-2"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!potentialJob.unitNumber ||
                          !!potentialJob.lotRangeLow ||
                          !!potentialJob.lotRangeHigh ||
                          !!potentialJob.streetRangeLow ||
                          !!potentialJob.streetRangeHigh
                        }
                      />
                      <span className="mr-2">-</span>
                      <input
                        type="number"
                        name="unitRangeHigh"
                        defaultValue={potentialJob.unitRangeHigh}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!potentialJob.unitNumber ||
                          !!potentialJob.lotRangeLow ||
                          !!potentialJob.lotRangeHigh ||
                          !!potentialJob.streetRangeLow ||
                          !!potentialJob.streetRangeHigh
                        }
                      />
                    </div>
                  </FormField>
                  <FormField label="Street Number">
                    <input
                      type="text"
                      name="streetNumber"
                      value={potentialJob.streetNumber}
                      onChange={handleChange}
                      className="form-control"
                      disabled={!!potentialJob.streetRangeLow || !!potentialJob.streetRangeHigh}
                    />
                  </FormField>
                  <FormField label="Street Range">
                    <div className="form-group d-flex align-items-center">
                      <input
                        type="number"
                        name="streetRangeLow"
                        defaultValue={potentialJob.streetRangeLow}
                        onChange={handleChange}
                        className="form-control mr-2"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!potentialJob.streetNumber ||
                          !!potentialJob.lotRangeLow ||
                          !!potentialJob.lotRangeHigh ||
                          !!potentialJob.unitRangeLow ||
                          !!potentialJob.unitRangeHigh
                        }
                      />
                      <span className="mr-2">-</span>
                      <input
                        type="number"
                        name="streetRangeHigh"
                        defaultValue={potentialJob.streetRangeHigh}
                        onChange={handleChange}
                        className="form-control"
                        style={{ maxWidth: '100px' }}
                        disabled={
                          !!potentialJob.streetNumber ||
                          !!potentialJob.lotRangeLow ||
                          !!potentialJob.lotRangeHigh ||
                          !!potentialJob.unitRangeLow ||
                          !!potentialJob.unitRangeHigh
                        }
                      />
                    </div>
                  </FormField>
                  <FormField label="Street Name">
                    <input
                      type="text"
                      name="streetName"
                      value={potentialJob.streetName}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="Road Type">
                    <input
                      type="text"
                      name="roadType"
                      value={potentialJob.roadType}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="Suburb">
                    <input
                      type="text"
                      name="suburb"
                      value={potentialJob.suburb}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="State">
                    <input
                      type="text"
                      name="state"
                      value={potentialJob.state}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                  <FormField label="Post Code">
                    <input
                      type="text"
                      name="postCode"
                      value={potentialJob.postCode}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </FormField>
                </>
              )}
              <FormField label="Comments">
                <input
                  type="text"
                  name="comments"
                  value={potentialJob.comments}
                  onChange={handleChange}
                  className="form-control"
                />
              </FormField>
            </form>
          </ModalBody>
        )}
        {!!selectedJobsData && (
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Builder</th>
                  <th>Site Contact</th>
                </tr>
              </thead>
              <tbody>
                {selectedJobsData.jobs.map((job, index) => (
                  <tr key={index}>
                    <td>{job.needsOfficeApproval ? job.addressFromField : getAddress(job)}</td>
                    <td>{job.builder}</td>
                    <td>{(job.siteContactName ?? '') + ' ' + (job.siteContactPhone ?? '')}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ModalBody>
        )}
        <ModalFooter>
          <div className="d-flex justify-content-between w-100 mt-1 align-items-center flex-wrap">
            <div>
              {!selectedJobsData && !fullAddressOnly && !jobData && (
                <Button
                  className="float-left"
                  color="primary"
                  onClick={() => {
                    const showFullAddressNewValue = !showFullAddress;
                    setShowFullAddress(showFullAddressNewValue);
                    setPotentialJob({ ...potentialJob, needsOfficeApproval: !showFullAddressNewValue });
                  }}
                >
                  {showFullAddress ? 'Hide Detailed Address Fields' : 'Show Detailed Address Fields'}
                </Button>
              )}
            </div>
            <div>
              <Button
                className="mr-1"
                color="secondary"
                onClick={() => setShowPotentialJobModal(!showPotentialJobModal)}
              >
                {selectedJobsData ? 'Done' : 'Cancel'}
              </Button>
              {!selectedJobsData && (
                <Button
                  color="primary"
                  disabled={
                    (!potentialJob.streetName || !potentialJob.suburb) && (!potentialJob.addressFromField || !!jobData)
                  }
                  title={!potentialJob.streetName || !potentialJob.suburb ? 'Please fill in the required fields' : ''}
                  onClick={() => {
                    updatePotentialJob({ variables: { potentialJob, processedFromOffice: !!jobData } });
                  }}
                >
                  {!!jobData ? 'Submit' : 'Save'}
                </Button>
              )}
              {selectedJobsData && isValidPhoneNumber && !jobData && !fullAddressOnly && (
                <Button color="primary" onClick={() => setShowBuilderApproval(true)}>
                  Draft Builder Approval Message
                </Button>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {showBuilderApproval && selectedJobsData && (
        <DraftBuilderApprovalMessage
          selectedJobsData={selectedJobsData}
          requestSent={requestSent}
          handleCloseApproval={handleCloseApproval}
        />
      )}
    </>
  );
};

export default NewPotentialJob;

const BuilderSelect = ({ builderList, selectedBuilder, setSelectedBuilder }) => {
  const [options, setOptions] = useState(
    builderList.map((builder) => ({
      label: builder,
      value: builder
    }))
  );

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedBuilder(selectedOption.value);
    } else {
      setSelectedBuilder('');
    }
  };

  const handleInputChange = (inputValue, { action }) => {
    if (action === 'input-change') {
      setSelectedBuilder(inputValue);
    }
  };

  const handleBlur = () => {
    if (selectedBuilder && !options.find((option) => option.value === selectedBuilder)) {
      const newOption = { label: selectedBuilder, value: selectedBuilder };
      setOptions([...options, newOption]);
    }
  };

  return (
    <div style={{ width: '260px' }}>
      <Select
        options={options}
        value={selectedBuilder ? { label: selectedBuilder, value: selectedBuilder } : null}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onBlur={handleBlur}
        isClearable
        placeholder="Select or type a builder"
        classNamePrefix="react-select"
      />
    </div>
  );
};
