import moment from 'moment-timezone';

export const getAddress = (job) => {
  if (!job) return 'no job, no address'; // should never happen

  if (
    job.lotNumber !== null ||
    job.unitNumber !== null ||
    job.streetNumber !== null ||
    job.streetName !== null ||
    job.roadType !== null ||
    job.suburb !== null ||
    job.state !== null ||
    job.postCode !== null
  ) {
    const addressComponents = [];
    if (job.lotNumber) {
      addressComponents.push('Lot ' + job.lotNumber);
    }

    if (job.unitNumber) {
      addressComponents.push('U' + job.unitNumber);
    }

    if (job.streetNumber) {
      addressComponents.push(job.streetNumber);
    }

    if (job.streetName) {
      addressComponents.push(job.streetName);
    }

    if (job.roadType) {
      addressComponents.push(job.roadType);
    }

    if (job.suburb) {
      addressComponents.push(job.suburb);
    }

    if (job.state) {
      addressComponents.push(job.state);
    }

    if (job.postCode) {
      addressComponents.push(job.postCode);
    }

    return addressComponents.join(' ');
  } else {
    return '';
  }
};

export const getAddressRange = (jobs) => {
  if (!jobs || jobs.length === 0) return 'no job, no address';

  const getRange = (numbers) => {
    if (numbers.length === 0) return '';
    const minNumber = Math.min(...numbers);
    const maxNumber = Math.max(...numbers);
    return minNumber === maxNumber ? `${minNumber}` : `${minNumber} - ${maxNumber}`;
  };

  const getAddressComponents = (job, range, isRange) => {
    const addressComponents = [];
    if (isRange) {
      addressComponents.push(range);
    } else {
      if (job.lotNumber) {
        addressComponents.push('Lot ' + job.lotNumber);
      } else if (job.unitNumber) {
        addressComponents.push('U' + job.unitNumber);
      } else if (job.streetNumber) {
        addressComponents.push(job.streetNumber);
      }
    }

    if (job.streetName) {
      addressComponents.push(job.streetName);
    }
    if (job.roadType) {
      addressComponents.push(job.roadType);
    }
    if (job.suburb) {
      addressComponents.push(job.suburb);
    }
    if (job.state) {
      addressComponents.push(job.state);
    }
    if (job.postCode) {
      addressComponents.push(job.postCode);
    }

    return addressComponents.join(' ');
  };

  const lotNumbers = [...new Set(jobs.map((job) => job.lotNumber).filter(Boolean))];
  if (lotNumbers.length > 1) {
    const range = `Lot ${getRange(lotNumbers)}`;
    return getAddressComponents(jobs[0], range, true);
  }

  const unitNumbers = [...new Set(jobs.map((job) => job.unitNumber).filter(Boolean))];
  if (unitNumbers.length > 1) {
    const lotOrStreetNumber = jobs[0].lotNumber ? 'Lot ' + jobs[0].lotNumber : jobs[0].streetNumber;
    const range = `${lotOrStreetNumber} U${getRange(unitNumbers)}`;
    return getAddressComponents(jobs[0], range, true);
  }

  const streetNumbers = [...new Set(jobs.map((job) => job.streetNumber).filter(Boolean))];
  if (streetNumbers.length > 1) {
    const range = getRange(streetNumbers);
    return getAddressComponents(jobs[0], range, true);
  }

  // Handle the case when there's only one job or no range
  return getAddressComponents(jobs[0], '', false);
};

/**
 * @param {Array} settings - array of objects to be sorted by sortOrder
 * @returns {Array} - sorted array of objects
 * @description - this function will sort an array of objects by sortOrder. - Must include sortOrder in the object
 */
export const initializeSortOrder = (settings) => {
  let sortedSettings = [...settings];

  if (sortedSettings.length) {
    if (
      sortedSettings.length !== sortedSettings.filter((x) => x.sortOrder === 0).length &&
      sortedSettings.every((x) => 'sortOrder' in x)
    ) {
      sortedSettings.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    //if there's any discrepancies in sortOrder, this will fix them
    sortedSettings = sortedSettings.map((x, i) => {
      return {
        ...x,
        ['sortOrder']: i
      };
    });
  }
  return sortedSettings;
};

/**
 * @param dateTime - utc date time string
 * @returns {string} - formatted date string 'DD MM YYYY'
 * @description - this function will format a DateTime object to a string 'DD MM YYYY'
 */
export const formatDate = (dateTime) => {
  if (!dateTime) return null;
  const formattedDateTime = moment.utc(dateTime).tz(moment.tz.guess()).format('DD MM YYYY');
  return formattedDateTime;
};

/**
 * @param dateTime - utc date time string
 * @returns {string} - formatted date string 'DD MM YYYY HH:mm A'
 * @description - this function will format a DateTime object to a string 'DD MM YYYY HH:mm A'
 */
export const formatDateTime = (dateTime) => {
  if (!dateTime) return null;
  const formattedDateTime = moment.utc(dateTime).tz(moment.tz.guess()).format('DD MM YYYY HH:mm A');
  return formattedDateTime;
};

export const getBaseUrl = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};
